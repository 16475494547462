let nativeBridgeConfig = {
  environment: null,
  nativeCall: null
};

function initNativeBridge() {
  if (typeof window.flutter_inappwebview !== 'undefined') {
    console.log('flutter_inappwebview');
    nativeBridgeConfig.environment = 'Flutter InAppWebView';
    nativeBridgeConfig.nativeCall = function (methodName, ...args) {
      try {
        const result = window.flutter_inappwebview.callHandler(methodName, ...args);
        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  } else if (typeof window.android !== 'undefined') {
    console.log('android');
      nativeBridgeConfig.environment = 'Native Android';
      nativeBridgeConfig.nativeCall = function (methodName, ...args) {
        try {
          const result = window.android[methodName](...args);
          return Promise.resolve(result);
        } catch (error) {
          return Promise.reject(error);
        }
      };
  } else {
    nativeBridgeConfig.environment = 'Unknown';
    nativeBridgeConfig.nativeCall = () => {
      throw new Error('Unsupported environment for native calls.');
    };
  }
}

export { initNativeBridge, nativeBridgeConfig };
